export interface Status {
	color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'amethyst',
	label: string,
	clientView: string
}

const StatusesConst: Record<string, Status> = {
	ACCOUNT             : { color: 'primary', label: 'Account', clientView: 'Account' },
	DRAFT               : { color: 'warning', label: 'Draft', clientView: 'Unpaid' },
	STANDING            : { color: 'error', label: 'Recurring', clientView: 'Recurring' },
	MERGED              : { color: 'success', label: 'Merged', clientView: 'Merged' },
	INVOICED            : { color: 'success', label: 'Invoiced', clientView: 'Invoiced' },
	SENT                : { color: 'primary', label: 'Sent', clientView: 'Unpaid' },
	UNSENT              : { color: 'warning', label: 'Unsent', clientView: 'UnSent' },
	PRINTED             : { color: 'secondary', label: 'Printed', clientView: 'Printed' },
	VIEWED              : { color: 'amethyst', label: 'Viewed', clientView: 'Unpaid' },
	PAID                : { color: 'success', label: 'Paid', clientView: 'Paid' },
	PARTIALLY_PAID      : { color: 'secondary', label: 'Partially Paid', clientView: 'Partially Paid' },
	CONFIRMED           : { color: 'amethyst', label: 'Confirmed', clientView: 'Confirmed' },
	APPROVED            : { color: 'success', label: 'Approved', clientView: 'Approved' },
	ISSUES              : { color: 'warning', label: 'Issues', clientView: 'Issues' },
	REVISED             : { color: 'info', label: 'Revised', clientView: 'Revised' },
	RECEIVED            : { color: 'success', label: 'Received', clientView: 'Received' },
	PARTIALLY_RECEIVED  : { color: 'primary', label: 'Partially Received', clientView: 'Partially Received' },
	REFUNDED            : { color: 'warning', label: 'Refunded', clientView: 'Refunded' },
	DECLINED            : { color: 'warning', label: 'Declined', clientView: 'Declined' },
	CANCELLED           : { color: 'error', label: 'Cancelled', clientView: 'Cancelled' },
	FAILED              : { color: 'error', label: 'Failed', clientView: 'Failed' },
	DAMAGED             : { color: 'warning', label: 'Damaged', clientView: 'Damaged' },
	LOST                : { color: 'error', label: 'Lost', clientView: 'Lost' },
	COMPLETED           : { color: 'success', label: 'Approved', clientView: 'Approved' },
	READY               : { color: 'warning', label: 'Ready', clientView: 'Ready' },
	DELIVERED           : { color: 'success', label: 'Delivered', clientView: 'Delivered' },
	PICKED_UP           : { color: 'success', label: 'Picked up', clientView: 'Picked up' },
	ARRIVED             : { color: 'primary', label: 'Arrived', clientView: 'Arrived' },
	ORDERED             : { color: 'primary', label: 'Ordered', clientView: 'Ordered' },
	DONE                : { color: 'success', label: 'Done', clientView: 'Done' },
	PENDING             : { color: 'warning', label: 'Pending', clientView: 'Pending' },
	COLLECTIONS         : { color: 'error', label: 'Collections', clientView: 'Collections' },
	WRITTEN_OFF         : { color: 'error', label: 'Written Off', clientView: 'Unpaid' },
	VOIDED              : { color: 'error', label: 'Voided', clientView: 'Voided' },
	PAST_DUE            : { color: 'error', label: 'Past Due', clientView: 'Past Due' },
	CLOSED              : { color: 'error', label: 'Closed', clientView: 'Closed' },
	PENDING_VERIFICATION: { color: 'warning', label: 'Pending Verification', clientView: 'Pending Verification' },
	DONATED             : { color: 'warning', label: 'Donated', clientView: 'Donated' },
};

const estimateStatuses = {
	...StatusesConst,
	DRAFT   : { color: 'warning', label: 'Draft', clientView: 'Draft' },
	SENT    : { color: 'primary', label: 'Sent', clientView: 'Pending' },
	PAID    : { color: 'success', label: 'Invoiced', clientView: 'Invoiced' },
	DECLINED: { color: 'warning', label: 'Declined', clientView: 'Declined' },
	EXPIRED : { color: 'error', label: 'Expired', clientView: 'Expired' },
};

const CommerceStatusConst = {
	INVOICE: {
		...StatusesConst,
	},
	ACCOUNT: {
		...StatusesConst,
	},
	ORDER: {
		...StatusesConst,
		DRAFT: { color: 'warning', label: 'Open', clientView: 'Open' },
	},
	ESTIMATE: {
		...estimateStatuses,
	},
	QUOTE: {
		...estimateStatuses,
	},
	PROPOSAL: {
		...estimateStatuses,
	},
	BID: {
		...estimateStatuses,
	},
	WORK_ORDER: {
		...estimateStatuses,
	},
	PURCHASE: {
		...StatusesConst,
		DRAFT: { color: 'warning', label: 'Draft', clientView: 'Pending' },
		SENT : { color: 'primary', label: 'Sent', clientView: 'Submitted' },
	},
	SUBSCRIPTION: StatusesConst,
	STATEMENT   : StatusesConst,
};
export {
	StatusesConst as Statuses,
	CommerceStatusConst as CommerceStatus,
};

// export const Statuses = new Proxy( StatusesConst, handler );
// export const CommerceStatus = new Proxy( CommerceStatusConst, handler );
